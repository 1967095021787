import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import { useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import PlaceholderTextBox from '../../../shared/placeholder/PlaceholderTextBox';
import { FormConfig } from '../../../../models/Form';
import { AnswerPlaceholderWordPlugin } from '../../../shared/placeholder/AnswerPlaceholderWord';
import { EventSystem } from '../../../../events/EventSystem';
import MultiTextField from '../../../shared/form-control/MultiTextField';
import { FormType } from '../../../../models/FormTypes';
import { MenuAction } from '../../../shared/placeholder/PlaceholderSelectMenu';
import { PlaceholderTarget } from '../../../form-builder/FormBuilderTypes';

export type TextinputActionData = {
  rows: number;
  prefilledText?: string;
  allowDynamicData?: boolean;
  requiresPrefilledText?: boolean;
};

type TextInputActionProps = ActionBaseProps<string, TextinputActionData>;

const TextInputAction: FC<TextInputActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { rows, prefilledText, requiresPrefilledText, allowDynamicData } = data;
  const { config, placeholders } = useFormRendererInfo();
  const { onAnswer, currentAction, createPlaceholder, deletePlaceholder, busySavingAction, currentSection, readOnly } = useFormAction(props);
  const [value, setValue] = useState(response || '');
  const [inputKey, setInputKey] = useState(0);
  const [dynamicDataMenuOpen, setDynamicDataMenuOpen] = useState(false);
  const [placeholdersAdded, setPlaceholdersAdded] = useState(false);

  useEffect(() => {
    setValue(response || '');
  }, [response]);

  useEffect(() => {
    if (requiresPrefilledText && prefilledText && !value.trim()) {
      setInputKey((prev) => prev + 1);
    }
  }, [onAnswer, prefilledText, requiresPrefilledText, value]);

  const onBlur = useCallback(() => {
    if (readOnly) return;
    if (!!value && value === (response || '')) return;
    if (dynamicDataMenuOpen || busySavingAction) return;
    onAnswer(value);
  }, [busySavingAction, dynamicDataMenuOpen, onAnswer, readOnly, response, value]);

  useEffect(() => {
    if (!value && !!response) {
      onAnswer('');
    }
  }, [onAnswer, response, value]);

  useEffect(() => {
    const openedHandler = ({ action }: { action: MenuAction }) => {
      if (currentAction.id !== action.targetId || currentAction.fieldId !== action.fieldId) return;
      setDynamicDataMenuOpen(true);
    };
    const closedHandler = ({ action }: { action: MenuAction }) => {
      if (currentAction.id !== action.targetId || currentAction.fieldId !== action.fieldId) return;
      setDynamicDataMenuOpen(false);
    };

    EventSystem.listen('dynamic-data-popup-opened', openedHandler);
    EventSystem.listen('dynamic-data-popup-closed', closedHandler);

    return () => {
      EventSystem.stopListening('dynamic-data-popup-opened', openedHandler);
      EventSystem.stopListening('dynamic-data-popup-closed', closedHandler);
    };
  }, [busySavingAction, currentAction.fieldId, currentAction.id, onAnswer, response, value]);

  const onFocus = useCallback(() => {
    if (requiresPrefilledText && prefilledText && !value) {
      setTimeout(() => {
        setValue(prefilledText);
        onAnswer(prefilledText);
      }, 10);
    }
  }, [onAnswer, prefilledText, requiresPrefilledText, value]);

  const wordTagPlugin = useMemo(() => AnswerPlaceholderWordPlugin(placeholders || {}), [placeholders]);

  const textboxAction = useMemo(() => ({ ...currentAction, targetId: currentAction.id, target: PlaceholderTarget.Action }), [currentAction]);

  return (
    <div className="flex flex-col" data-cy="text-input-action">
      <ActionTitleDescription required={required} {...data} />
      {config.type === FormType.Resource && (
        <MultiTextField
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onBlur={(event) => onAnswer(event.target.value)}
          placeholder={t('text-input.placeholder')}
          disabled={readOnly}
          rows={rows || 1}
        />
      )}
      {config.type !== FormType.Resource && (
        <PlaceholderTextBox
          key={inputKey}
          initialValue={value || (requiresPrefilledText ? prefilledText : '') || ''}
          action={textboxAction}
          form={config}
          onTextChange={(value) => {
            setValue(value);
            if (placeholdersAdded && !busySavingAction) {
              onAnswer(value);
              setPlaceholdersAdded(false);
            }
          }}
          onPlaceholdersChange={(newPlaceholders) => {
            for (const placeholder of newPlaceholders) {
              if (placeholders[placeholder.placeholder]) continue;
              createPlaceholder(placeholder);
            }
            setPlaceholdersAdded(true);
          }}
          onRemovedPlaceholder={deletePlaceholder}
          referencedForms={{} as Record<string, FormConfig>}
          disabled={readOnly}
          singleLine={rows === 1}
          inputPlaceholder={t('text-input.placeholder')}
          enableMarkdown
          enableDynamicData={!currentSection.isPublic ? allowDynamicData : false} // using ternary here because enableDynamicData defaults to true
          toolbarPosition="bottom"
          rows={rows}
          wordTagPlugin={wordTagPlugin}
          opacity={requiresPrefilledText ? (value.trim() ? 1 : 0.4) : 1}
          onFocus={onFocus}
          allowExternalDynamicData={false}
          onBlur={onBlur}
        />
      )}
    </div>
  );
};

export default TextInputAction;
